import { graphql } from "gatsby"
import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"
import Button from "@bit/azheng.joshua-tree.button/Button"

const LanguageProceduresPage = ({ data, location }) => {
  const {
    metaTitle,
    metaDescription,
    heading,
    content,
    youtube,
    disclaimer,
    buttons,
    language
  } = data.allLanguageProceduresJson.nodes[0]

  return (
    <Layout language="en">
      <SEO
        lang={language}
        title={metaTitle}
        description={metaDescription}
        pathname={location.pathname}
      />
      <div style={{ marginTop: 150, marginBottom: 60 }} className="columns">
        <div className="column is-4"></div>
        <div className="column">
          <h1
            style={{ paddingLeft: 40, paddingRight: 40 }}
            className="has-text-centered"
          >
            {heading}
          </h1>

          <div
            style={{
              position: "relative",
              paddingBottom: "56.25%",
              width: "100%",
              marginBottom: "2rem"
            }}
          >
            <iframe
              style={{
                position: "absolute",
                inset: 0,
                width: "100%",
                height: "100%"
              }}
              width="560"
              height="315"
              src={`https://www.youtube.com/embed/${youtube}`}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>

          <MarkdownViewer markdown={content} />

          <p
            style={{ marginTop: "1.5rem", marginBottom: "2rem" }}
            className="has-text-centered"
          >
            <i>{disclaimer}</i>
          </p>

          <div
            className="button-group is-centered is-centered-mobile"
            style={{ width: "100%", gap: 40 }}
          >
            {buttons.map((button, index) => (
              <a
                title={button.button.buttonText}
                className="standard-button contained"
                style={{ width: 300, margin: 0 }}
                key={index}
                href={button.button.href}
              >
                {button.button.buttonText}
              </a>
            ))}
          </div>
        </div>
        <div className="column is-4"></div>
      </div>
    </Layout>
  )
}

export const languageProceduresPageQuery = graphql`
  query languageProceduresPage($title: String!) {
    allLanguageProceduresJson(filter: { title: { eq: $title } }) {
      nodes {
        metaTitle
        metaDescription
        language
        heading
        content
        youtube
        disclaimer
        buttons {
          button {
            buttonText
            href
            appearance
            destination
          }
        }
      }
    }
  }
`

export default LanguageProceduresPage
